<template>

  <div style="height: inherit">
    <patrimoine-add-new
      :is-add-new-ouvrage-sidebar-active.sync="isAddNewPatrimoineSidebarActive"
      :inOuvrageData="inPatrimoineData"
      :assettype-List="Assettypes"
      :inCenter="inPatrimoineData.center"
      :worksite="worksite"
    @addouvrage='(ouvrage) => addPatrimoine(ouvrage)' 
    />  
   <!-- Filters -->
    <patrimoine-list-filters
      :asset-options="$userContext.assetList"   
      :worksite-options="$userContext.worksiteList" 
      :asset-typelist="Assettypes"  
      :worksite="worksite" 
      @changeMode="(val) => {currentView = val}"
      @changeAsset="(asset) => handleSearch(asset)"
    />
    <patrimoine-list :asset-list="localFilteredAssetList" :worksite-list="localFilteredWorksites" v-show="currentView === 'list'" :asset-typelist="Assettypes" />

    <patrimoine-map :asset-list="localFilteredAssetList" :worksite-list="localFilteredWorksites"   :originpoint="currentUo" v-show="currentView === 'map'" :asset-typelist="Assettypes" @addAsset="(center) => onPatrimoineNew(center)" @onSelectWorksite="(assetid) => onWorksiteSelect(assetid)" />
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'

import PatrimoineMap from '@/views/apps/patrimoine/patrimoine-carte/PatrimoineMap.vue'
import PatrimoineList from '@/views/apps/patrimoine/patrimoine-carte/PatrimoineList.vue'
import PatrimoineAddNew from '@/views/apps/patrimoine/patrimoine-carte/PatrimoineAddNew.vue'

import PatrimoineListFilters from '@/views/apps/patrimoine/patrimoine-carte/PatrimoineListFilters.vue'
import Assettypes from '@/@fake-db/data/apps/assettype.js'
import alohaStoreModule from '@/api/alohaStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  directives: {
    Ripple,
  },

  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    alohaStoreModule,
    // SFC
    PatrimoineList,
    PatrimoineMap,
    PatrimoineListFilters,
    PatrimoineAddNew,
  },
  mounted() {
    this.$userContextInitialize(1)
    this.fetchUserContext() 


  window.addEventListener('usercontext-changed', (event) => {
    this.fetchUserContext() 
  });


},

methods:{

    onWorksiteSelect: function(wkId) {
      this.worksite = this.$userContext.worksiteList.find(a => a.id === wkId)
       //this.$userContextSetFilteredWorksiteList(true,[wkId])
    },

   handleSearch: function(val) {

     let filters = {wks:[],assets:[],types:[],queries:[],iqoas:[]} 

     val.forEach(
       a =>{
        if (typeof(a.id) !== "undefined") {
          if (a.type === 'worksite') {
              filters.wks[a.id] = a.id
          } else if (a.type === 'asset') {
              filters.assets.push(a.id)
          } else if(a.type === 'type-asset' || a.type === 'type-datalogger') {
              filters.types.push(a.id)
          } else if (a.type === 'iqoa') {
              filters.iqoas.push(a.id.replace('x',''))
          }  
        } else {
          filters.queries.push(a.name.toLowerCase())
        }
       }
     )
     this.$userContextSetFilteredWorksiteList(false,filters.wks)
     if (filters.wks.length !== 1 ) this.worksite = {}
     this.$userContextSetFilteredAssetList(true,filters)

  }, 

     addPatrimoine: function (patrimoine) { 
       delete patrimoine.image
       patrimoine.image = require("@/assets/images/pages/patrimoine/pont8.jpeg")

      store
      .dispatch('app-aloha/addAsset',patrimoine)
      .then(response => {
          this.$router.push({ path: '/apps/patrimoine/fiche/'+response.data.asset.id})
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })
      //this.itemsCarac.push(carac)
    },  

   onPatrimoineEdit: function (patrimoine) { 
       this.inPatrimoineData = patrimoine     
       this.isAddNewPatrimoineSidebarActive = true
    }, 

     onPatrimoineNew: function (center) { 
       this.inPatrimoineData.id = ''  
       this.inPatrimoineData.center = center 
       this.isAddNewPatrimoineSidebarActive = true
    },  

  fetchUserContext: function() {

    this.currentUo = this.$userContext.uo
    
    this.localFilteredWorksites = this.$userContext.filteredWorksiteList
    this.localFilteredAssetList = this.$userContext.filteredAssetList

    if (this.$userContext.filteredWorksiteList.length === 1) {
     // this.worksite = this.currentUo = this.$userContext.filteredWorksiteList[0]
      this.currentUo = this.$userContext.filteredWorksiteList[0]
    }  

    if (this.$userContext.assetFilter !== -1) {
       this.currentUo = this.$userContext.assetList.find(a => a.id === this.$userContext.assetFilter)   
    }
  }
},

  setup() {

    const USER_APP_STORE_MODULE_NAME = 'app-aloha'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, alohaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })


    const isAddNewPatrimoineSidebarActive = ref(false)
    return { 
      Assettypes,
      isAddNewPatrimoineSidebarActive
    }
  },

  data() {

    return {
      currentView: 'map',
      inPatrimoineData:{id:'',type:'', center:[]},
      uoId: '12',
      localFilteredWorksites: [],
      localFilteredAssetList: [],
      worksite:{},
      reload:-1,
      q:'',
      currentUo: {center:[45.76746308908782, 5.609893798828126], zoomlevel:13}
    }
  },



}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.card-img-top {
  height: 155px;
}
.item-img {
  align-content: center;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
    padding-top: 0;
    min-height: 1rem;
}
.card-body {
    padding: 0.5rem !important;
}

.ecommerce-application .grid-view {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
</style>


