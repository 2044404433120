<template>
<div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="assetList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(type)="data">   
            <span> {{ assetTypelist[data.item.type].name }} </span>
              
        </template>

        <template #cell(name)="data">
            <b-link
              @click="onGotoFiche(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
        </template>        

        <!-- Column: Role -->
        <template #cell(parentid)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ $userContext.worksiteList.find(w => w.id === data.item.parentid).name }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(uoid)="data">
          <span class="align-text-top text-capitalize">     
              <b-link
              @click="onGotoFiche(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
             Dossier d'ouvrage
            </b-link>
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(image)="data">
          <span class="align-text-top text-capitalize">     
              <b-link
              @click="onGotoCarnet(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              Carnet de santé
            </b-link>
          </span>
        </template>        

        <!-- Column: Status -->
        <template #cell(iqoa)="data">
          <div class="containeriqoa">
          <img v-if="data.item.iqoa === '1'" :src="require('@/assets/images/ficheiqoa/gb.png')" height="35px" width="35px"/>
          <img v-if="data.item.iqoa === '2' || data.item.iqoa === '2E'" :src="require('@/assets/images/ficheiqoa/yellow.png')" height="35px" width="35px"/>
          <img v-if="data.item.iqoa === '3' || data.item.iqoa === '3U'" :src="require('@/assets/images/ficheiqoa/red.png')" height="35px" width="35px"/>
          			
            <div class="text">
              {{ data.item.iqoa }}
            </div>
          </div>
        </template> 



      </b-table>
      </div>
      </template>

      <script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BImg
} from 'bootstrap-vue'


export default {
  components: {


    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BImg,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },

  props: {
    assetList: {
        type: Array,
        default: () => [
        ]        
    },
    assetTypelist: {
        type: Object,
        default: () => {}        
    },     
    worksiteList: {
        type: Array,
        default: () => [
        ]        
    },      
  },  

    setup() {

        const tableColumns = [
            { key: 'iqoa',label:"IQOA", sortable: true }, 
            { key: 'name',label:"Nom", sortable: true },  
            { key: 'type',label:"Type", sortable: true },            
            { key: 'parentid',label:"Plateforme", sortable: true }, 
            { key: 'uoid',label:"" }, 
            { key: 'image',label:""}, 
        ]
        return { tableColumns}
    },

  methods: {
    onGotoFiche: function(item) {
      this.$router.push({ path: '/apps/patrimoine/fiche/'+item.id})
    },
    onGotoCarnet: function(item) {
      this.$router.push({ path: '/apps/patrimoine/carnet/'+item.id})
    }
  },


  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.containeriqoa {
    display: inline-block;
    position: relative;
}

.containeriqoa .text {

    z-index: 1;
    position: absolute;
    text-align: center;
    font-family: Georgia;
    font-size: large;
    font-weight: bold;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 10%;
    color: rgb(97, 67, 207);
    width: 70%;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
