<template>
    <div ref="map-root" id="map" style="width:100%;height:750px;" @contextmenu="event => openContextMenu(event)">    
    <context-menu :display="showContextMenu" ref="menu">
          <ul class="contextmenu">
            <!--<li class="amgt"> <a @click="addNewAsset('worksite')">Ajouter un aménagement</a> </li>-->
            <li class="wksite"> <a @click="addNewAsset">Ajouter un ouvrage</a> </li>
            <!--<li class="datalogger"> <a @click="addNewAsset('datalogger')">Ajouter un appareil de mesure</a> </li>>-->
            <!--<li class="datalogger"> <a @click="addNewAsset('sensor')">Ajouter un capteur</a> </li>>-->
          </ul>
    </context-menu>
    <context-menu :display="showMapSwitcher" ref="menuMap">
          <ul class="contextmenu">
            <li class="menumap"> <a @click="switchMap('osm')">OSM</a> </li>
            <li class="menumap"> <a @click="switchMap('GeoOrtho')">IGN Ortho</a></li>
            <li class="menumap"> <a @click="switchMap('ign')">IGN Plan</a> </li>
          </ul>
    </context-menu>
    <context-menu  :display="showPopover" ref="menupop">
          <ul class="contextmenu">
            <li class="tooltipmap">     
              <div class="arrow" style="top: 58px;"></div>
                <h3 class="popover-header" ref="tooltipTitle"></h3>
                <div class="popover-body"  ref="tooltipBody"></div>
                <div class="popover-body"  ref="tooltipLinks">
                  <ul class="contextmenu">
                    <li class="menumap"> <a @click="gotoFiche">Voir le dossier d'ouvrage</a> </li>
                    <li class="menumap"> <a @click="gotoCarnet">Voir le carnet de Santé </a> </li>
                  </ul>
                  
                </div>
            </li>
          </ul>
    </context-menu >    
    </div>

</template>

<script>
/* eslint-disable global-require */
import View from 'ol/View'
import Map from 'ol/Map'

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import WMTS from 'ol/source/WMTS';

import ContextMenu from './ContextMenu';

import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {fromLonLat, get as getProjection} from 'ol/proj';

import {getWidth} from 'ol/extent';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  BLink
} from 'bootstrap-vue'

import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';

import {Style, Icon, Text, Fill, Stroke} from 'ol/style';


import {Services, olExtended} from 'geoportal-extensions-openlayers';
import {Control, defaults as defaultControls} from 'ol/control';

/* eslint-disable global-require */
  // importing the OpenLayers stylesheet is required for having
  // good looking buttons!
  import 'ol/ol.css'



export default {
    name: 'MapContainer',
    components: {FontAwesomeIcon,ContextMenu,BLink},  
  props: {
    assetList: {
        type: Array,
        default: () => [
        ]        
    },
    assetTypelist: {
        type: Object,
        default: () => {}        
    },    
    originpoint: {
        type: Object,
        default: () => {return {center:[48.88994726542142, 2.3524475097656254], zoomlevel:13}}
    },    
    worksiteList: {
        type: Array,
        default: () => [
        ]        
    },      
  },  

  watch: {
    originpoint: {
      handler () { 
        if (this.originpoint && this.view) {
          this.flyTo(this.originpoint.center, this.originpoint.zoomlevel)
         
          this.map.getLayers().forEach(
            layer => {
              if (layer.get('name')) layer.setVisible(this.$userContextGetLayerVisibility(layer.get('name')) 
          )})          
        }
      }
    },
    assetList: {
      handler () { 
        if (this.assetList && this.map) {
          this.loadDataLayer()

        }
      }      
    },
    worksiteList: {
      handler () { 
        if (this.worksiteList && this.map) {

          this.loadDataLayer()

        }
      }      
    },      
  },  
  methods: {

    openContextMenu(e) {
      e.preventDefault();
      this.$refs.menu.open(e);  

      var coords = toLonLat(this.map.getEventCoordinate(e))
      this.lat = coords[1]
      this.long = coords[0]

    },

    openPopover(e) {
      e.preventDefault();
      this.$refs.menupop.open(e);  



    },    

    addNewAsset: function() {
      let center = [this.long,this.lat]
      this.$emit("addAsset",center)
    },

    openContextMenuMap(e) {
         // e.preventDefault();
         this.$refs.menuMap.open(e);
    },    

    switchMap: function(mapName) {
      this.viewMap = mapName
      if (mapName === 'GeoOrtho') {     
        this.loadGeolayer()
        this.loadDataLayer()
      } else if (mapName === "ign") {
        this.loadIGN()
         this.loadDataLayer()
      } else {
        this.loadOSMlayer()
         this.loadDataLayer()
      }
      this.$refs.menuMap.close();
    },  


    flyTo: function(location,zoomtarget) {
      //var view = map.getView();
      this.view.animate({
          center: fromLonLat(location),
          zoom:   zoomtarget
      });
      /*const duration = 2000;
      const zoom = this.view.getZoom();
      this.view.animate(
        {
          center: fromLonLat(location),
          duration: duration,
        },
      );
      this.view.animate(
        {
          zoom: zoom - 1,
          duration: duration / 2,
        },
        {
          zoom: zoomtarget,
          duration: duration / 2,
        },
      );*/

    },

    addAsset: function(asset) {

      let feat = new Feature({name:asset.name,
        geometry: new Point(fromLonLat(asset.center)),
        assettype:asset.type,
        center:asset.center,
        assetid: asset.id,
        assetimg: asset.image,
        assetiqoa: asset.iqoa,
        zoomlevel:asset.zoomlevel
      })

        let icon = require('@/assets/images/ficheiqoa/red.png')
        if (asset.type !== 'worksite') {
          switch(asset.iqoa) {
            case "1":
              icon = require('@/assets/images/ficheiqoa/gb.png')
              break;
            case "2":
            case "2E" :
              icon = require('@/assets/images/ficheiqoa/yellow.png')
              break;
          }
        } else {
            icon = this.assetTypelist[asset.type].icon
        }

        feat.setStyle(new Style({
          image: new Icon({
                  src: icon,
                  scale: this.assetTypelist[asset.type].scale
          }),
          text: new Text({
                  text: asset.name,
                  offsetY: 20,
                  scale: 1.2,
                  fill: new Fill({
                    color: "#fff"
                  }),
                  stroke: new Stroke({
                    color: "0",
                    width: 3
                  })  
          })              
        }));
       this.features[asset.type].push(feat)
    },


    loadGeolayer: function() {

          let geolayer = new olExtended.layer.GeoportalWMTS({
                layer : "ORTHOIMAGERY.ORTHOPHOTOS",
            })

          geolayer.setZIndex( 0 )
          this.map.getLayers().forEach(layer => {
              this.map.removeLayer(layer);
          });

         this.map.addLayer(geolayer)
    },

    loadOSMlayer: function() {  
         this.map.addLayer(new TileLayer({
              source: new OSM(),
              visible: true // tiles are served by OpenStreetMap
            }) )
    },

    loadDataLayer:function() {
          this.features = []
          for (const [key, value] of Object.entries(this.assetTypelist)) {
            this.features[key] = []
            this.map.getLayers().forEach(layer => {
              if (layer && layer.get('name') === key) {
                this.map.removeLayer(layer);
              }
            });
          }

          this.assetList.forEach(asset => this.addAsset(asset))
          this.worksiteList.forEach(asset => this.addAsset(asset))
          let a = 1

          for (const [key, value] of Object.entries(this.features)) {
              
              if (value.length > 0) {
                let vectorSource = new VectorSource({
                  features: value,
                });

                let vectorLayer = new VectorLayer({
                  source: vectorSource,
                  name:key,
                });
                vectorLayer.setZIndex( 1000 + a )
                
                vectorLayer.setVisible(this.$userContextGetLayerVisibility(key))
                a++
                this.map.getLayers().forEach(layer => {
                  if (layer && layer.get('name') === key) {
                    this.map.removeLayer(layer);
                  }
                });
                this.map.addLayer(vectorLayer)
              }
          }

    
    },

    center: function(){},
    marker: function() {},

loadIGN: function() {
    const resolutions = [];
    const matrixIds = [];
    const proj3857 = getProjection('EPSG:3857');
    const maxResolution = getWidth(proj3857.getExtent()) / 256;

    for (let i = 0; i < 20; i++) {
      matrixIds[i] = i.toString();
      resolutions[i] = maxResolution / Math.pow(2, i);
    }

    const tileGrid = new WMTSTileGrid({
      origin: [-20037508, 20037508],
      resolutions: resolutions,
      matrixIds: matrixIds,
    });

    // For more information about the IGN API key see
    // https://geoservices.ign.fr/blog/2021/01/29/Maj_Cles_Geoservices.html

    const ign_source = new WMTS({
      url: 'https://wxs.ign.fr/choisirgeoportail/geoportail/wmts',
      layer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
      matrixSet: 'PM',
      format: 'image/png',
      projection: 'EPSG:3857',
      tileGrid: tileGrid,
      style: 'normal',
      attributions:
        '<a href="https://www.ign.fr/" target="_blank">' +
        '<img src="https://wxs.ign.fr/static/logos/IGN/IGN.gif" title="Institut national de l\'' +
        'information géographique et forestière" alt="IGN"></a>',
    });

    const ign = new TileLayer({
      source: ign_source,
    });

    ign.setZIndex( 0 )
    this.map.getLayers().forEach(layer => {
        this.map.removeLayer(layer);
    });

    this.map.addLayer(ign);
    
},


    mapOnCLick: function(evt) {
     // alert('{id: 58,type: "barrage",name: "BAR-128",parentid: 1,uoid:2,zoomlevel:17,center: ['+transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326')+'],},' );
         
        var feature = this.map.forEachFeatureAtPixel(evt.pixel,
          function(feature) {
            return feature;
          });  
        if (feature) {
          if (feature.get('assettype')==='worksite') 
            this.zoomToFeature(feature)
            else {
              this.openPopover(evt)
            }
        }

 },

      mapOnPointerMove: function(evt) {

          let feature = this.map.forEachFeatureAtPixel(evt.pixel, function (f) {
            return f;
          });

          if (feature && feature.get('assettype')!=='worksite' && this.tooltipId !== feature.get('assetid')) {
            this.tooltipId = feature.get('assetid')
            this.$refs.tooltipTitle.innerHTML = feature.get('name')
            let typeName = ""
            for (const [key, value] of Object.entries(this.assetTypelist)) {
              if (key === feature.get('assettype'))
                typeName = value.name
            }
            this.$refs.tooltipBody.innerHTML = '<img src="'+feature.get('assetimg')+'" height="130px" width="260px"><br/> Iqoa : '+ feature.get('assetiqoa') +'<br/>Type : '+ typeName +''  

              this.openPopover(evt)
          }
        },   
    
    gotoCarnet: function() {
      this.$router.push({ path: '/apps/patrimoine/carnet/'+this.tooltipId})
    },

    gotoFiche: function() {
      this.$router.push({ path: '/apps/patrimoine/fiche/'+this.tooltipId})
    },

    zoomToFeature: function(feature) {
          this.flyTo(feature.get('center'),feature.get('zoomlevel'))
          this.$emit('onSelectWorksite',feature.get('assetid'))
    }


  },

    mounted() {
      this.viewMap = 'osm'
   /* proj4.defs([
      //WSG84
      [
        'EPSG:4326',
        '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'],
      //Lambert 93
      [
        'EPSG:2154',
        '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
      ],
      //NTF(PARIS) LAmbert Zone II => peut être mais pas sûr...
      [
        'EPSG:27572',
        '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '
      ],      
      
    ]);*/
      //console.log(proj4('EPSG:4326','EPSG:27572',[4.898529052734376,44.93460780727755]))


      class MyControl extends Control {
        /**
         * @param {Object} [opt_options] Control options.
         */
        constructor(opt_options) {
          const options = opt_options || {};

          const button = document.createElement('button');
          button.innerHTML = 'M';
          button.id = 'tooglemapbutton'

          const element = document.createElement('div');
          element.className = 'rotate-north ol-unselectable ol-control';

          element.appendChild(button);

          super({
            element: element,
            target: options.target,
          });

          //button.addEventListener('click', switchMap, false);
        }

      }




        this.view = new View({
          center: fromLonLat([2.3524475097656254,48.88994726542142]),
          zoom: 7,
        });




        // this is where we create the OpenLayers map
        this.map = new Map({
          // the map will be created using the 'map-root' ref
          target: this.$refs['map-root'],
          controls: defaultControls().extend([new MyControl()]),
          layers: [  
          ],
          view: this.view,
          })

/*
            var layerImport = new olExtended.control.LayerImport();
            this.map.addControl(layerImport);
            var mp = new olExtended.control.GeoportalMousePosition();
            this.map.addControl(mp);

            var measureLength = new olExtended.control.MeasureLength();
            this.map.addControl(measureLength);
            var measureArea = new olExtended.control.MeasureArea();
            this.map.addControl(measureArea);
*/


        this.map.on('click', evt => this.mapOnCLick(evt))     
        
        this.map.on('pointermove', e => this.mapOnPointerMove(e))

      document.getElementById("tooglemapbutton").addEventListener('click', (e) => this.openContextMenuMap(e));          
           
        if (this.viewMap === 'GeoOrtho') {
          Services.getConfig({
              apiKey : "ortho",
              timeOut : 20000,
              onSuccess: this.loadGeolayer
          });
        } else if (this.viewMap === 'ign') {
          Services.getConfig({
              apiKey : "ortho",
              timeOut : 20000,
              onSuccess: this.loadIGN
          });          
        } else {  
          Services.getConfig({
              apiKey : "ortho",
              timeOut : 20000,
          });
          this.loadOSMlayer()
        }

    },


  data() {
 
    return {
      features:[],
      showContextMenu: false ,
      showMapSwitcher: false,
      showPopover:false,
      tooltipId:0,
    }
  },
}
</script>
<!-- this.$refs.lmap.mapObject -->
<style lang="scss">
      .rotate-north {
        top: 65px;
        left: .5em;
      }
      .ol-touch .rotate-north {
        top: 80px;
      }

.amgt {

 background-image:url(/img/worksite2.399bc570.png);

 background-size: 7%;
 padding-left: 25px;
   margin:10px;
} 

.wksite {

 background-image:url(/img/Barrages.2f29aa63.png);

 background-size: 7%;
 padding-left: 25px;
   margin:10px;
} 

.datalogger {

 background-image:url(/img/datalogger.4e2af1ee.png);

 background-size: 7%;
 padding-left: 25px;
   margin:10px;
} 


.contextmenu li {
 font-family: Arial, sans-serif;
 font-size: 100%;
 color: black;
 list-style-type: none;
 background-repeat: no-repeat;
 background-position: 0 0.02em;
}

.contextmenu {
  padding:0;
  margin:10 px;
}
.menumap {
 font-family: Arial, sans-serif;
 font-size: 100%;
 color: black;
  padding-left: 25px;
 list-style-type: none;  
}

div[id^="GPimport-"] {
    top: 100px;
    left:0.5em;
}
.rotate-north button {
  width:26px;
  height:26px;
}

div[id^=GPmousePosition-] {
    top: 135px;
    left:0.5em;
}

div[id^=GPtoolbox-measure-main] {
    top: 170px;
    left:0.5em;
}
</style>
 
