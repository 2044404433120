<template>
  <b-sidebar
    id="add-new-ouvrage-sidebar"
    :visible="isAddNewOuvrageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-ouvrage-sidebar-active', val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajout d'un nouvel ouvrage
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Nom"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="ouvrageData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="parentid"
            rules="required"
          >
            <b-form-group
              label="Plateforme"
              label-for="parentid"
            >
              <v-select
                v-model="ouvrageData.parentObj"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options=plateformeOptions
                item-value="value"
                item-text="label"
                :clearable="false"
              />   

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="type"
          >
            <b-form-group
              label="Type ITSEOA"
              label-for="type"
            >
              <v-select
                v-model="ouvrageData.typeObj"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options=typeOptions
                :clearable="false"
                item-value="value"
                item-text="label"
              />   

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> 

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="created"
            rules="required"
          >
            <b-form-group
              label="Année de construction"
              label-for="created"
            >
              <b-form-input
                id="created"
                v-model="ouvrageData.created"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>                 


          <validation-provider
            name="imageshow"
          >
            <b-form-group
                            label="Photo d'illustration :"
                            label-for="photo"
                            >
                  <img v-if="!ouvrageData.image.length>0" src="@/assets/images/pages/placeholder.png"  height="150px" float="right"/>
                  <img v-if="ouvrageData.image.length>0" src="@/assets/images/pages/patrimoine/pont8.jpeg" width="200px" float="right"/>

            </b-form-group>  
          </validation-provider>

          <validation-provider
            name="image"
          >
            <b-form-group
                            >
                    <b-form-file
                    placeholder="Choisir une image d'illustration"
                    multiple
                    v-model="ouvrageData.image"
                    />

            </b-form-group>  
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!ouvrageData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Créer
            </b-button>
            <b-button v-if="ouvrageData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Mettre à jour
            </b-button>            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Assettypes from '@/@fake-db/data/apps/assettype.js'


export default {
  components: {
    BSidebar,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewOuvrageSidebarActive',
    prop: 'assettypeList',
    event: 'update:is-add-new-ouvrage-sidebar-active',
  },
  props: {
    isAddNewOuvrageSidebarActive: {
      type: Boolean,
      required: true,
    },
    inOuvrageData: {
      type: Object,
      default: (() => {id:''}),
    },  
    worksite: {
      type: Object,
      default: (() => {id:''}),
    },     
    inCenter: {
      type: Array,
      default: [],
    },       
    assettypeList: {
      type: Object,
      default: (() => {}),
    }, 
  },

  watch: {
    worksite: {
      handler () { 
        this.loadOuvrage()
      }
    },
  },




 methods: {
    loadOuvrage() {
        this.ouvrageData = JSON.parse(JSON.stringify(this.blankOuvrageData))
    },
    showOuvrage: function (val) { 


      if (val) {
        this.loadOuvrage()
      } else {
        this.ouvrageData = JSON.parse(JSON.stringify(this.blankOuvrageData))
      }

      if (this.$userContext.filters.wks.length === 1)
        this.ouvrageData.parentObj = this.$userContext.worksiteList.find(a => a.id ===  this.$userContext.filters.wks[0])

      if (this.inCenter.length > 0)
        this.ouvrageData.center = this.inCenter

    },
 },  
 
  data() {

    this.loadOuvrage()
    this.plateformeOptions = []
    this.$userContext.worksiteList.forEach( a => {
      this.plateformeOptions.push({value:a.id, label:a.name})
    })

    this.typeOptions = []
    for (const [key, value] of Object.entries(this.Assettypes)) {
        if (value.type !== "worksite")
          this.typeOptions.push({value:key, label:value.name})
    }

    return {
      required,
      alphaNum,
      email,
    }
  },


  setup(props, { emit }) {
    const blankOuvrageData = {
      id: '',
      name: '',
      type: '',
      typeObj:{type:''},
      created: '',
      iqoa: '1',
      zoomlevel:18, 
      uoid:1,
      image: [],
      parentid: '',
      parentObj:'',
      center: []
    }
       
    let ouvrageData = ref(JSON.parse(JSON.stringify(blankOuvrageData)))


    if (props.inOuvrageData.id != '') {
      ouvrageData.value = JSON.parse(JSON.stringify(blankOuvrageData))
    }

    const resetouvrageData = () => {
      ouvrageData.value = JSON.parse(JSON.stringify(blankOuvrageData))
    }

    const onSubmit = () => {

      ouvrageData.value.parentid = ouvrageData.value.parentObj.value
      delete ouvrageData.value.parentObj
      ouvrageData.value.type = ouvrageData.value.typeObj.value
      delete ouvrageData.value.typeObj
      ouvrageData.value.center = props.inCenter

      emit('update:is-add-new-ouvrage-sidebar-active', false)
      emit('addouvrage', ouvrageData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetouvrageData)

    return {
      ouvrageData ,
      onSubmit,
      blankOuvrageData,
      Assettypes,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-ouvrage-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
