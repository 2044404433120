<template>
  <b-card no-body class="mb-0">
    <b-card-body class="mb-0 myclass">
      <b-row>       
        <b-col
          cols="12"
          md="9"
          class="mb-md-0 mb-2"
        >
          <label>Recherche</label>
          <search-box 
            :asset-list="localAssetList"
            :selected1="selectedAssets"
            @changeAsset="(asset) => $emit('changeAsset', asset)"
            />
        </b-col>        
        <b-col           cols="12"
          md="3"
          class="mb-md-0 mb-2 buttonswitch">
          <button class="mybutton" v-on:click="showMode('map')"><img height="30px" width="30px" :src="require('@/assets/images/ficheiqoa/map.png')" /></button>
          <button class="mybutton" v-on:click="showMode('list')"><img height="30px" width="30px" :src="require('@/assets/images/ficheiqoa/list2.png')" /></button>
          <b-button
            variant="primary"
            @click="onNewOuvrage"
            class="ml-2"
          >
            <span class="text-nowrap">Ajouter un ouvrage</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,BInputGroup,BInputGroupAppend,BFormInput, BFormRadioGroup, BIcon, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SearchBox from './SearchBox'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormRadioGroup,
    SearchBox,
    BIcon
  },

  watch: {
      worksite: {
      handler () { 
        if (typeof(this.worksite.id) !== 'undefined')
          this.selectedAssets = {name:this.worksite.name, id:this.worksite.id, image:this.assetTypelist[this.worksite.type].icon, type:'worksite'}
      }
    }                      
    },

  mounted() {
    this.buildAssetlist()
  },

  data() {
    
    return {
      viewOptions : [
        {value:'map',text:'Vue Carte'},
        {value:'list',text:'Vue Liste'}
      ],
      viewMode: 'map',
      localAssetList:[],
      selectedAssets:{}
    }
  },

  methods: {

    buildAssetlist: function() {
        this.localAssetList = []
        
       this.worksiteOptions.forEach(w => this.localAssetList.push({name:w.name, id:w.id, image:this.assetTypelist[w.type].icon, type:'worksite'}))
       
       this.assetOptions.forEach(a => this.localAssetList.push({name:a.name, id:a.id, image:this.assetTypelist[a.type].icon, type:'asset'}))        

        let b = ["1","2","2E","3","3U"]
        b.forEach(a => this.localAssetList.push({name:'IQOA '+a, id:a+'x', image:this.assetTypelist['worksite'].icon, type:'iqoa'}))

       for (const [key, value] of Object.entries(this.assetTypelist)) {
          if (key !== 'worksite')
            this.localAssetList.push({name:value.name, id:key, image:value.icon, type:'type-'+value.type})
        }
    },

    onNewOuvrage: function() {
      alert('La création d\'un ouvrage se fait via un clic droit sur la carte pour le positionner de manière précise.')     
    },

    showMode: function(mode) {
      this.viewMode = mode
      this.$emit('changeMode', this.viewMode)
    }
  },

  props: { 
    assetOptions: {
      type: Array,
      required: true,
    },    
    worksiteOptions: {
      type: Array,
      required: true,
    },     
    assetTypelist: {
        type: Object,
        default: () => {}        
    },  
    worksite: {
        type: Object,
        default: () => {}        
    },         
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.myclass {
  padding: 0.5rem;
}

.buttonswitch {
display: flex;
align-items: center;
justify-content: right;
}

.mybutton {
  background-color: white; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
</style>
